<script>
	import Logo from '$lib/components/Logo.svelte';
	import { updated } from '$app/stores';
	import { validateShoppingBagVersion } from '$lib/stores/shoppingBag';
	import { onMount } from 'svelte';
	import '../lib/assets/global.css';

	onMount(() => {
		validateShoppingBagVersion();
	});
</script>

<noscript>
	<div class="no-script-container">
		<div class="logo">
			<Logo />
		</div>
		<div
			class="no-js"
			style="background-color: #f8f8f8; padding: 20px; text-align: center; font-family: Arial, sans-serif;"
		>
			<h1 style="color: #333; font-size: 24px; margin-bottom: 10px;">JavaScript Required</h1>
			<p style="color: #666; font-size: 16px; margin-bottom: 20px;">
				Our website requires JavaScript to function. Please enable JavaScript in your browser
				settings.
			</p>
			<p style="color: #999; font-size: 14px;">
				If you need help enabling JavaScript, <a
					href="https://www.enable-javascript.com/"
					style="color: #06c;">click here</a
				> for instructions.
			</p>
		</div>
	</div>
</noscript>

<div class="app" data-sveltekit-reload={$updated ? '' : 'off'}>
	<slot />
</div>

<style>
	.logo {
		display: flex;
		justify-content: center;
		padding: 20px;
	}

	.no-script-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-family: Arial, sans-serif;
		z-index: 9999;
		background-color: #f8f8f8;
	}
</style>
